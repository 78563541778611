import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { FeesService } from '../../services/fees.service'
import Swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment'
import { b } from '@angular/core/src/render3';

@Component({
    selector: 'app-fees-list',
    templateUrl: './fees-list.component.html',
    styleUrls: ['./fees-list.component.scss']
})
export class FeesListComponent implements OnInit {
    public oldSite = environment.apiSite;
    public location = '';
    jobId
    recordsCount = 0;
    feesList = [];
    loderDivShow = true;
    loading = false;
    branches: any;
    branches_prased: any;
    constructor(
        config: NgbModalConfig,
        private _router: Router,
        private router: ActivatedRoute,
        private feesService: FeesService
    ) {
        let locationHash = _router.url.split("/");
        if (locationHash.length >= 2) {
            this.location = locationHash[1] + '/' + locationHash[2];
        }
        config.backdrop = 'static';
        config.keyboard = false;
    }

    ngOnInit() {
        this.getBranchList();
        // after that get the fees list
    }

    getFees() {
        this.feesService.getAllFees().subscribe(
            data => {
                this.recordsCount = data.result.data.length;
                var fees = data.result.data;
                fees.forEach(element => {
                    if (Number(element.branch_id) > 0) {
                        // get the branch name.
                        if (this.branches_prased !== undefined && this.branches_prased[element.branch_id] !== undefined) {
                            element.branch_name = this.branches_prased[element.branch_id].branch_name;
                        } else {
                            element.branch_name = "";
                        }
                    }
                });
                this.feesList = fees;
                this.loderDivShow = false;
            }
        )
    }

    deleteFee(fee_id) {
        Swal({
            title: 'Are you sure?',
            text: 'Delete Fee!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                this.feesService.deleteFee(fee_id).subscribe(
                    data => {
                        this.getFees();
                        Swal(
                            'Deleted!',
                            'Fee has been deleted.',
                            'success'
                        )
                    }
                );

            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        })
    }

    getBranchList() {
        this.feesService.getBranchList()
            .subscribe(
                data => {
                    this.branches = data.result.branches;
                    var branchs_parsed = [];
                    this.branches.forEach(element => {
                        branchs_parsed[element.branch_id] = element;
                    });
                    this.branches_prased = branchs_parsed;

                    // get fees list.
                    this.getFees();
                }
            );
    }
}
