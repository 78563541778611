import { Component, OnInit } from '@angular/core';
import {NgbDateParserFormatter, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from "./../inspections-scheduled/ngb-date-fr-parser-formatter";
import {CommonService} from '../../services/common/common.service';
import {ReportService} from '../../services/report/report.service';


 

@Component({
  selector: 'app-agent-activity-report',
  templateUrl: './agent-activity-report.component.html',
  styleUrls: ['./agent-activity-report.component.scss'],
  providers: [{provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter},CommonService ],
})


export class AgentActivityReportComponent implements OnInit {


  currentPage = 1;
  lastPage = 1;
  pageParam = 1;
  report = {
    search_key:'',
    branch:'',
    start:{},
    end:{},
    min_inspections:'',
    min_completions:'',
    page: this.pageParam
  };
  branches=[];
  inspectors = [];
  errordetails='';
  sales=[];
  loderDivShow = false;
  rows = [
    { name: '', first_name : '', last_name : '', email: '', phone: '', inspections: '', reinspections: '', completions: '', percentage: '', completion: '', total: '',perComplete: '',perInspection: '',},
    
  ];
  columns = [
    { name: 'name', 'prop' : 'name' },
    { name: 'email', 'prop' : 'email' },
    { name: 'phone', 'prop' : 'phone' },
    { name: 'Inspections', 'prop' : 'inspections' },
    { name: 'Reinspections', 'prop' : 'reinspections' },
    { name: 'Completions', 'prop' : 'completions' },
    { name: '%(Percentage)', 'prop' : 'percentage' },
    { name: 'Completion $', 'prop' : 'completion' },
    { name: 'Total $', 'prop' : 'total' },
    { name: '$ Per Complete', 'prop' : 'perComplete' },
    { name: '$ Per Inspection', 'prop' : 'perInspection' },
  ];
  constructor(private salesReport: ReportService, private calendar: NgbCalendar, private commonService: CommonService) { }

  ngOnInit() {
    this.getBranch();
  }
  getBranch() {
    this.commonService.getBranch().subscribe(
      data => { 
                this.branches = data;
              }
    );
  }
  clearActivity(){
    this.report = {   search_key:'',
                      branch:'',
                      start:{},
                      end:{},
                      min_inspections:'',
                      min_completions:'',
                      page: 1
                    };
  }
  inspectorSalesReport(){
    this.loderDivShow = true;
    this.report.page = 1;
    this.sales = [];
    this.inspectorSalesPagingQuery();
  }

  inspectorSalesPagingQuery() {
    this.salesReport.agentSalesReport(this.report).subscribe(
      data => { 
                this.errordetails='';
                if(data.data.client){
                  this.sales = this.sales.concat(data.data.client);
                  this.rows=[...this.sales];
                }else{
                    
                }
                this.currentPage = data.data.currentPage;
                this.lastPage = data.data.lastPage;
                this.pageParam = data.data.currentPage + 1;
                
                if(this.currentPage != this.lastPage) {
                  this.report.page = data.data.currentPage + 1;
                  this.inspectorSalesPagingQuery();
                } else {
                  this.loderDivShow = false;
                  if(this.sales.length == 0) {
                    this.errordetails='No Data';
                  }
                }  
               
              },
      error =>{
                this.loderDivShow = false;
                this.errordetails = error; 
              }
    ); 
  }

export(){
  var data = this.rows;
  
  let arrayOfJson = data.map(
    obj => {
        return {
            "First Name" : obj.first_name,
            "Last Name" : obj.last_name,
            "Email" : obj.email,
            "Phone" : obj.phone,
            "Inspections" : obj.inspections,
            "Reinspections" : obj.reinspections,
            "Completions" : obj.completions,
            "%(Percentage)" : obj.percentage, 
            "Completion $" : obj.completion,
            "Total" : obj.total,
            "$ Per Complete" : obj.perComplete,
            "$ Per Inspection" : obj.perInspection
    } });

  var filename = 'agent-report-sales.csv'; 
  // convert JSON to CSV
  const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
  const header = Object.keys(arrayOfJson[0])
  let csv = arrayOfJson.map(row => header.map(fieldName => 
  JSON.stringify(row[fieldName], replacer)).join(','))
  csv.unshift(header.join(','));
  let newCsv = csv.join('\r\n');
  // Create link and download
  var link = document.createElement('a');
  link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(newCsv));
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}


}
