import { Component, OnInit, ViewChild } from '@angular/core';

import { SettingService } from '../../services/setting.service';

@Component({
  selector: 'app-billing-report-settings',
  templateUrl: './billing-report-setting.component.html',
  styleUrls: ['./billing-report-setting.component.scss']
})
export class BillingReportSettingComponent implements OnInit {
  billingFooter: any = {
    data: ''
  };

  settingId: number;
  billingFooterSectionLoader = false;
  sucMsgBillingFooterSectionLoader: boolean;
  constructor(private settingService: SettingService) {

  }

  ngOnInit() {
    this.getReportContent();
  }

  @ViewChild('tinymce') tinymce;

  getReportContent() {
    this.settingService.getSettingData().subscribe(
      data => {
        this.settingId = data.setting_id;
        this.billingFooter.data = data.billing_statement_pdf_footer;
      }
    );
  }

  onSubmitBillingFooters() {
    this.billingFooterSectionLoader = true;
    this.settingService.setBillingFootersSection(this.settingId, this.billingFooter).subscribe(
      data => {
        this.billingFooterSectionLoader = false;
        this.sucMsgBillingFooterSectionLoader = true;
      }
    );
  }

}
