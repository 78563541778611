import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import{ SharedService } from '../../services/shared.service'
import { QuickWidgetFormModalComponent } from '../quick-widget-form-modal/quick-widget-form-modal.component';

@Component({
  selector: 'app-quick-widget',
  templateUrl: './quick-widget.component.html',
  styleUrls: ['./quick-widget.component.scss']
})
export class QuickWidgetComponent implements OnInit {
		widgetList = [];
    
		
		
	  constructor(
			config: NgbModalConfig,
		  private modalService: NgbModal,
			private sharedService:SharedService,

	  ) { 
			config.backdrop = 'static';
			config.keyboard = false;
		}

	ngOnInit() {
		this.getWidgets();

	}


  
  openFormModal(id) {
	 	  // console.log("id",id);
		  const modalRef = this.modalService.open(QuickWidgetFormModalComponent);
		  modalRef.componentInstance.widget_id = id;

	  
	  	modalRef.result.then((result) => {
	    	this.getWidgets();
	  	}).catch((error) => {
        // console.log(error);
	  });
	}
	discardWidget(id){
		this.sharedService.removeWidget(id).subscribe(
			data => { 
				this.getWidgets();
					// console.log(data);
					}
			);
	}
	is_loading
	getWidgets() {
		this.widgetList=[];
		this.sharedService.getWidgets().subscribe(
		  data=> {
			// console.log("ddf");	
			// console.log("ddf");	
			for(let wid of data) {
				this.is_loading=false;
				this.sharedService.getWidgetDatas(wid).subscribe(
					data=> {
						this.is_loading=true
						if(data){
							this.widgetList.push({ 
								id: wid.widget_id, 
								title: data.title,
								result_data: data.data,
								symbol: data.data_type_symbol,
								goal: data.goal,
								is_multiple: data.is_multiple,
								is_null_result: data.is_null_result,
								exceed_goal: data.exceed_goal,
								percentage_value : data.percentage_value,
								datapercentage_val : data.datapercentage_val,
								shouldbepercentage_val: data.shouldbepercentage_val,
								shouldbe_color : data.shouldbe_color,
								displayStartDate: data.displayStartDate,
								displayEndDate: data.displayEndDate,
								diffrence:data.diffrence,
								target:data.target,
								shouldbe:data.shouldbe,
								created: wid.created,
								order_id:wid.order_id
							})

						}
            		
					// const result = this.widgetList.filter(word => word.order_id =-1);
					// console.log("test",result);
					 
					this.widgetList.sort(function(a, b){return a.order_id-b.order_id });

					}
				)
					
			}
			// this.widgetList.sort(function(a, b){return a.order_id-b.order_id });
		  }
		)
		
		}
		// sort=false
		// text='sort'
		// sortwidget(){
		// 	if(this.sort){
		// 		this.sort=false
		// 		this.text='Sort'
		// 	}else{
		// 		this.sort=true
		// 		this.text='Close'
		// 	}
			
		// }
		// onNotify(message):void {
		// 	let timeout
		// 	clearTimeout(timeout);
		// 	timeout = 	setTimeout(() => {
		// 		this.getWidgets();
		// 	}, 2000);
			
		// }
		
		

	

}
