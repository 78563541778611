import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FeesListComponent } from './pages/fees-list/fees-list.component';
import { AddFeeComponent } from './components/add-fee/add-fee.component';

// const routes: Routes = [];
const feesroutes: Routes = [
    { path: 'office-fees', component: FeesListComponent },
    { path: 'office-fees/add', component: AddFeeComponent },
    { path: 'office-fees/edit/:feeId', component: AddFeeComponent }
];
@NgModule({
    imports: [RouterModule.forChild(feesroutes)],
    exports: [RouterModule]
})
export class FeesRoutingModule { }
