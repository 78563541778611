import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { FeesService } from '../../services/fees.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { catchError } from "rxjs/operators";

@Component({
    selector: 'app-add-fee',
    templateUrl: './add-fee.component.html',
    styleUrls: ['./add-fee.component.scss']
})

export class AddFeeComponent implements OnInit {
    title = "Add Fee";
    error_message = '';
    submitted = false;
    is_valid = false;
    feeId: any;
    fee: any;
    branches: any;
    showBaseAmount: boolean = false;

    constructor(
        private fb: FormBuilder,
        private feesService: FeesService,
        private _router: Router,
        private router: ActivatedRoute,
    ) { }

    feeForm = this.fb.group({
        fee_id: [''],
        fee_name: ['', Validators.compose([Validators.required])],
        fee_amount: ['', Validators.compose([Validators.required])],
        fee_description: ['', Validators.compose([Validators.required])],
        branch_id: ['', Validators.compose([Validators.required])],
        square_max: [''],
        square_min: [''],
        foundation_type: ['', Validators.compose([Validators.required])],
        is_calculate_per_sq: [''],
        base_amount: [''],
    });

    ngOnInit() {
        this.router.params.subscribe(params => {
            if (params.feeId) {
                this.feeId = params.feeId;
                this.getFee(this.feeId);
                this.title = "Edit Fee";
            } else {
                // should get the branch list.
                this.getBranchList();
                this.feeId = 0;
            }
        })
    }

    addFee() {
        this.is_valid = true;
        this.feesService.addFee(this.feeForm.value)
            .subscribe(
                data => {
                    this.submitted = false;
                    this.is_valid = false;
                    this._router.navigate(['office-fees']);
                }, error => {
                    this.error_message = typeof error.errors != 'undefined' ? (error.errors == 'object' ? error.errors.join('<br>') : error.errors)
                        : (typeof error.message != 'undefined' ? error.message : error);
                    this.submitted = false;
                    this.is_valid = false;
                }
            );
    }

    toggleBaseAmount() {
        this.showBaseAmount = this.feeForm.get('is_calculate_per_sq').value == 1 ? true : false;
    }

    onSubmit() {
        this.submitted = true;
        if (this.feeForm.invalid) {
            return false;
        }
        this.addFee();
    }

    getFee(feeId) {
        this.feesService.getSingleFee(feeId)
            .subscribe(
                data => {
                    this.fee = data;
                    this.branches = data.result.branches;
                    this.feeForm.patchValue({
                        fee_id: data.result.data.fee_id,
                        fee_name: data.result.data.fee_name,
                        fee_amount: data.result.data.fee_amount,
                        fee_description: data.result.data.fee_description,
                        branch_id: data.result.data.branch_id,
                        square_min: data.result.data.square_min,
                        square_max: data.result.data.square_max,
                        foundation_type: data.result.data.foundation_type,
                        is_calculate_per_sq: data.result.data.is_calculate_per_sq,
                        base_amount: data.result.data.base_amount,
                    });

                    this.toggleBaseAmount();
                }
            );
    }

    getBranchList() {
        this.feesService.getBranchList()
            .subscribe(
                data => {
                    this.branches = data.result.branches;
                }
            );
    }

}
