import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { JobcostingComponent }   from './component/jobcosting/jobcosting.component';
import { InspectionsScheduledComponent } from './component/inspections-scheduled/inspections-scheduled.component';
import { PayrollComponent }   from './component/payroll/payroll.component';
import { EstimatedActualComponent } from './component/estimated-actual/estimated-actual.component';
import { AccountingReportPayrollComponent } from './component/accounting-report-payroll/accounting-report-payroll.component';
import {AgentActivityComponent} from './component/agent-activity/agent-activity.component';
import {InspectorsSalesComponent} from './component/inspectors-sales/inspectors-sales.component';
import {AgentActivityReportComponent} from './component/agent-activity-report/agent-activity-report.component';
const secondaryRoutes: Routes = [
  { path: 'accounting-report-job-costing', component: JobcostingComponent },
  { path:'accounting-report-inspections-scheduled', component: InspectionsScheduledComponent },
  { path:'accounting-report-payroll-payment', component: PayrollComponent },
  { path:'accounting-report-payroll-charge' , component: AccountingReportPayrollComponent},
  { path:'accounting-report-estimated-vs-actual', component: EstimatedActualComponent },
  { path:'agent-activity' , component: AgentActivityComponent},
  { path:'agent-report-sales' , component: AgentActivityReportComponent},
  { path:'management-report-inspectors-sales' , component: InspectorsSalesComponent},
];

@NgModule({
  imports: [RouterModule.forChild(secondaryRoutes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }

