import { Component, OnInit, ViewChild } from '@angular/core';
import { Replay } from '../replay';
import { SettingService } from '../../../services/setting.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-canned-reply',
  templateUrl: './add-canned-reply.component.html',
  styleUrls: ['./add-canned-reply.component.scss']
})
export class AddCannedReplyComponent implements OnInit {

	refreshTrigger: number = 0;
	@ViewChild('tinymce') tinymce;
	billingFooter: any = {
	    data: ''
	};
	showForm = false;
	submitted = false;
	saveStatus = "";

	replay = new Replay(null, null, null, null, true);
  	constructor(private settingService: SettingService) { }

  	ngOnInit() {
  	}

  	clientSubmit(clientForm){
  		this.submitted = true;
  		this.settingService.newReply(this.replay).subscribe(
        data => {
          	this.submitted = false;
          	this.saveStatus = 'Saved';
          	this.refreshTrigger++;
          	clientForm.reset();
          	Swal({
	            title: 'Success',
	            text: 'Added successfully'
        	})
        }
      );
  	}

}
