export class Setting {
    constructor(
        public setting_bid_rate_crew_hours : any,
        public setting_bid_rate_materials : any,
        public setting_bid_rate_subcontractor : any,
        public setting_bid_rate_office : any
        ) {

    }
    
}   