import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FeesRoutingModule } from './fees-routing.module';
import { FeesListComponent } from './pages/fees-list/fees-list.component';
import { AddFeeComponent } from './components/add-fee/add-fee.component';

@NgModule({
    imports: [
        CommonModule,
        FeesRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
    ],
    declarations: [FeesListComponent, AddFeeComponent],
    entryComponents: [
        FeesListComponent
    ]
})
export class FeesModule { }
