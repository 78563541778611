import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {ReportPaymentComponent} from '../../../../child-component/report-payment/report-payment.component';
import {JobProofedComponent} from '../../../../child-component/job-proofed/job-proofed.component';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobService } from '../../../../services/job/job.service';

@Component({
  selector: 'overview-item-seven',
  templateUrl: './item-one.component.html',
  styleUrls: ['./item-accounting.component.scss']
})
export class ItemSevenComponent implements OnInit {
	@Input() job: any;
	@Input() balance: any;
	@Output () notify: EventEmitter<string> = new EventEmitter<string>();
	userData;
  isChecked: boolean = false;
  isCheckedProof: boolean = false;
	constructor(
		config : NgbModalConfig,
    	private modalService: NgbModal,
      public jobService:JobService,
    ) {
		config.backdrop = 'static';
    	config.keyboard = false;
	}
	ngOnInit() {
		this.userData = JSON.parse(localStorage.getItem('user'));
    this.isChecked = this.job.report_payment_status == 1 ? true : false;
    this.isCheckedProof = this.job.proofed_status == 1 ? true : false;
	}
  onToggleChange(e){
    let paymentStatus = this.isChecked == true ? "recieved" : "not-recieved"
    this.jobService.changeJobReportPayment({report_payment_status : paymentStatus} ,this.job.job_id).subscribe(
      data => {
      }
    );
  }
  onToggleChangeProof(e){
    let paymentStatus = this.isCheckedProof == true ? "yes" : "no"
    this.jobService.changeJobProofedStatus({proofed_status : paymentStatus} ,this.job.job_id).subscribe(
      data => {
      }
    );
  }
	openReportModal(id){
    const modalRef = this.modalService.open(ReportPaymentComponent,{size:'lg'});
    modalRef.componentInstance.jobData = this.job;
    modalRef.result.then((result)=>{
      this.job = result
      console.log("job data",this.job)
      this.notify.emit(this.job);
    }).catch((error)=>{
      console.log(error);
    })
  }
  openProofModal(id){
    const modalRef = this.modalService.open(JobProofedComponent);
    modalRef.componentInstance.jobData = this.job;
    modalRef.result.then((result)=>{
      this.job = result
      this.notify.emit(this.job);
    }).catch((error)=>{
    })
  }
}