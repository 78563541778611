import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { JobService } from '../../services/job/job.service';

@Component({
    selector: 'app-panel',
    templateUrl: './panel.component.html',
    styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit {
    @Input() page_title;
    public old_site;
    public location = '';
    public jobId = '';
    public allJobsData;
    tab = false;
    showJobsDropdown = false;
    parentChildJobId;
    currentURL = '';
    currentJobIsCancelled = false;
    selectedJob;

    constructor(
      private _router: Router,
      private router: ActivatedRoute,
      private jobService: JobService,
    ) {
        this.currentURL = _router.url;
        const locationHash = _router.url.split('/');
        if (locationHash.length >= 2) {
            this.location = locationHash[1];
        }
        if (locationHash.length === 3) {
            this.jobId = locationHash[2];
            this.tab = false;
        }
    }

    ngOnInit() {
        this.old_site = environment.apiSite;

        this.router.params.subscribe(params => {
            if (params.jobId) {
                this.parentChildJobId = this.jobId;
                this.getAllJobs();
                this.showJobsDropdown = true;
            } else {
                this.showJobsDropdown = false;
            }
        });
    }

    getAllJobs() {
        this.jobService.getAllJobs(this.jobId).subscribe(
            data => {

                this.allJobsData = data.result.data;
                const currentJob = this.allJobsData.find(el => el.job_id == this.jobId);
                this.selectedJob = currentJob;
                if (currentJob) {
                  this.currentJobIsCancelled = currentJob.job_status_top === 'Cancelled';
                }
            }
        );
    }

    goTotheParentJob() {
        // this._router.navigate([this.location+'/'+this.parentChildJobId]);
        window.location.href = this.location + '/' + this.parentChildJobId;
    }
    goTotheSelectedJob(id) {
        // this._router.navigate([this.location+'/'+this.parentChildJobId]);
        window.location.href = this.location + '/' + id;
    }

    onRightClick(){
        console.log('asdasd');
        return false;
    }
    stopPropagation(event: Event): void {
    event.stopPropagation();
  }

}
