export class Report {
    constructor(
        public report_id: any,
        public job_id: any,
        public report_description_general: any,
        public report_tags:any,
        public report_tags_other: any,
        public report_option_subterranean_termites:any,
        public report_option_drywood_termites: any,
        public report_option_fungus_dryrot: any,
        public report_option_other_findings: any,
        public report_option_further_inspection: any,
        public report_corrected_report: any,
        public report_notes: any

        

    ){

    }
}
