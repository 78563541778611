import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'overview-item-two',
  templateUrl: './item-two.component.html',
  styles: ['.item-one { padding: 10px; background-color: lightblue; }']
})
export class ItemTwoComponent implements OnInit {
	@Input() job: any;
	constructor() {

	}
	ngOnInit() {
	}
}