import { Component, OnInit, Input } from '@angular/core';
import {environment} from '../../../../../../../environments/environment';
@Component({
  selector: 'overview-item-order-history',
  templateUrl: './item-one.component.html',
  styles: ['.item-one { padding: 10px; background-color: lightblue; }']
})
export class ItemOrderHistoryComponent implements OnInit {
	@Input() job: any;
	public oldSite;
	constructor() {
		
	}
	ngOnInit() {
		this.oldSite = environment.apiSite;
	}
}