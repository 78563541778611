import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-job-costing-bout-inspector',
  templateUrl: './job-costing-bout-inspector.component.html',
  styleUrls: ['./job-costing-bout-inspector.component.scss']
})
export class JobCostingBoutInspectorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
