import { NgModule, Component } from '@angular/core';
import { RouterModule,Routes } from '@angular/router';

const intakeRoutes : Routes = [];  

@NgModule({
  imports: [],
  exports: [ ],
  declarations: []
})

export class LockoutRoutingModule { }
