import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../../../environments/environment'
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MapsAPILoader, AgmMap } from '@agm/core';
import { GoogleMapsAPIWrapper } from '@agm/core/services';
declare var google: any;

interface Location {
    lat: number;
    lng: number;
    zoom: number;
}
import {InspectionStatusComponent} from '../inspection-status/inspection-status.component';
@Component({
  selector: 'app-property-overview',
  templateUrl: './property-overview.component.html',
  styleUrls: ['./property-overview.component.scss'],
  // providers: [
  //   GoogleMapsAPIWrapper // <---
  // ]
})
export class PropertyOverviewComponent implements OnInit {
  @Input() job: any;
  @Input() jobData : any;
  @Output () notify: EventEmitter<string> = new EventEmitter<string>();
  map_button_status:string = "Show Map";
  map_status:boolean = true;
  geocoder: any;
  constructor(
    public mapsApiLoader: MapsAPILoader,
      // private zone: NgZone,
      private wrapper: GoogleMapsAPIWrapper,
      config : NgbModalConfig,
        private modalService: NgbModal
   ) {
      this.mapsApiLoader = mapsApiLoader;
      // this.zone = zone;
      // this.wrapper = wrapper;
      config.backdrop = 'static';
        config.keyboard = false;
        this.mapsApiLoader.load().then(() => {
            this.geocoder = new google.maps.Geocoder();
        });
  }
    panorama: any;
    regenerateMap: boolean = false;
    public location: Location = {
        lat: 51.678418,
        lng: 7.809007,
        zoom: 5
    };
  loca = {
    lat: 32.815173,
    lng: -116.83905950000002
  };
  userData;
  full_address = null;
  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('user'));
    console.log(this.job);
  }
  
  ngOnChanges(){  
  }
     regenerateAddress() {
        this.full_address = this.job.property_address1 + "," + this.job.property_city + "," + this.job.property_state + "," + this.job.property_zip;
        this.findLocation(this.full_address);
    }

    findLocation(address) {
        if (!this.geocoder) {
            this.geocoder = new google.maps.Geocoder()
        }
        this.geocoder.geocode({
            'address': address
        }, (results, status) => {

            if (status == google.maps.GeocoderStatus.OK) {
                if (results[0].geometry.location) {
                    this.location.lat = results[0].geometry.location.lat();
                    this.location.lng = results[0].geometry.location.lng();
                    this.job.property_lat = this.location.lat;
                    this.job.property_long = this.location.lng;
                }
                this.loca = {
                    lat: this.job.property_lat,
                    lng: this.job.property_long
                }
                console.log(this.map_button_status);
                if (this.map_button_status == "Hide Map") {
                    this.regenerateMap = true;
                    this.getStreetMap();
                }
                this.regenerateMap = false;
                // this.getStrtMap();
                // this.map.triggerResize()
            } else {
                alert("Sorry, this search produced no results.");
            }
        })
    }


  getStreetMap() {
        if (!this.regenerateMap) {
            if (this.map_button_status === "Show Map") {
                this.map_status = false;
                this.map_button_status = "Hide Map";
            } else {
                this.map_status = true;
                this.map_button_status = "Show Map";
            }
        }

        this.mapsApiLoader.load().then(() => {
            this.geocoder = new google.maps.Geocoder();
            // this.agmLazyMapsAPILoader();
            this.streetMap();
        });
        // this.regenerateAddress();

    }
  
  streetMap() {
    this.panorama = new google.maps.StreetViewPanorama(
        document.getElementById('panozz'), {
            position: this.loca,
            linksControl: false,
            panControl: false,
            enableCloseButton: false,
            fullscreenControl: false,
            zoomControl: false,
            motionTrackingControl: false,
            addressControl: false

        });
    // console.log(this.pana)
  }
  newStreetMap(){
        if (!this.regenerateMap) {
            if (this.map_button_status === "Show Map") {
                this.map_status = false;
                this.map_button_status = "Hide Map";
            } else {
                this.map_status = true;
                this.map_button_status = "Show Map";
            }
        }

        this.mapsApiLoader.load().then(() => {
            this.geocoder = new google.maps.Geocoder();
            this.regenerateAddress();
        });
    }
    openFormModal(id) {
        const modalRef = this.modalService.open(InspectionStatusComponent,{size:'lg'});
        modalRef.componentInstance.jobData = this.jobData;
        modalRef.result.then((result) => {
         
          this.jobData = result;
          this.notify.emit(this.jobData);
          // this.getTransactions();
        }).catch((error) => {
            console.log(error);
        });
    }
  onShowMap(){
    this.getStreetMap();
  }


}
