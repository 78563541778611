import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'overview-item-three',
  templateUrl: './item-one.component.html',
  styles: []
})
export class ItemThreeComponent implements OnInit {
	@Input() job: any;
	constructor() {
		
	}
	ngOnInit() {
	}
}