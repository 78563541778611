import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {NgbDateParserFormatter, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
 
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
  
})
export class CommonService {

  private apiURL = environment.api;
  public dateData = {};
  
  constructor(private http: HttpClient, private calendar: NgbCalendar) {
     
  }

  getBranch():Observable<any> {
      return this.http.get(this.apiURL+'branch').pipe(
        map(res => res) 
      );
  }
  getInspectors():Observable<any> {
      return this.http.get(this.apiURL+'inspectors').pipe(
        map(res => res) 
      );
  }

  getInspectorsWithPreferredLabel(job_id):Observable<any> {
    return this.http.get(this.apiURL+'inspectors-preferred/'+job_id).pipe(
      map(res => res) 
    );
  }

  getDate(selectedValue) {
    // this.dateData.start = 'llll';
    // this.dateData.end = 'lttttl';
    // console.log(this.dateData);
    // return this.datedata;
  }
  getdataSources():Observable<any> {
    return this.http.get(this.apiURL+'data_sources').pipe(
      map(res => res) 
    );
  }
  dateRangeSubmit(value ){
    // this.report.end = this.calendar.getToday();
    switch(value) {
      case '1':
          return this.calendar.getPrev(this.calendar.getToday(), 'd', 30);
      case '2':
          return this.calendar.getPrev(this.calendar.getToday(), 'd', 90);
      case '3':
          var date =(new Date()).getFullYear()
          return  {year: date, month: 1, day: 1};
      case '4':
          var year =(new Date()).getFullYear()
          var date =(new Date()).getUTCMonth()
          return  {year: year, month: date+1, day: 1};
      case '5' : 
      return this.calendar.getPrev(this.calendar.getToday(), 'd', 7);   
      default:
          // code block
    }  
    
  
  }
  

}
