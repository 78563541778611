//@ts-ignore
import { Finding } from '../popups/findings';

export const findingData = {
    
    finding : new Finding(null,null,null,null,null,null,null,0,0,
    null,null,{ code_special_pricing_notes : 'N/A',
        code_extras: null,
        code_finding: null,
        code_id: null,
        code_is_deleted: 0,
        code_name: null,
        code_notes: null,
        code_recommendation: null,
        code_uses_chemicals: 0 
    },
    null,0,0,0,0,0,0,null,null,0,0,0,0,0,0,0,'after',null,null,
    0,0,0,

    null,null,null, null   
    ) 

}