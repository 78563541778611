import { Component, OnInit, Input } from '@angular/core';
import {Router,ActivatedRoute } from '@angular/router';
import { JobService } from '../../services/job/job.service';
import {environment} from '../../../../../environments/environment'

interface Item {
  id: string;
  type: string;
}

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  page_title="Inspection: Review";
  public oldSite;
  jobId;
  userData;

  todo: Item[] = [];
  inProgress: Item[] = [];
  done: Item[] = [];
	constructor(
    // private commonService: CommonService,
    private jobService: JobService,
    private _router: Router,
    private router: ActivatedRoute,
  ) {


  }

	ngOnInit() {
    this.oldSite = environment.apiSite;
    this.router.params.subscribe(params => {
        if(params.jobId){
            this.jobId = params.jobId;
            console.log(this.jobId)
            this.getJob(this.jobId);
        }
    })
    this.userData = JSON.parse(localStorage.getItem('user'));
    this.loadState();
  }

  onDrop(event: any, targetList: string) {
    this.saveState();
  }

  loadState() {
    const savedState = localStorage.getItem('dragDropState');
    if (savedState) {
      const state = JSON.parse(savedState);
      this.todo = state.todo || [];
      this.inProgress = state.inProgress || [];
      this.done = state.done || [];
    } else {
      // Initialize with some default items if no saved state is found
      this.todo = [
          { id: '1', type: 'item1' },
          { id: '2', type: 'item2' },
          { id: '3', type: 'item3' }
      ];
      this.inProgress = [
          { id: '4', type: 'item4' },
          { id: '5', type: 'item5' },
          { id: '6', type: 'item6' },
          { id: '10', type: 'item10' }
      ];
      this.done = [
          { id: '7', type: 'item7' },
          { id: '8', type: 'item8' },
          { id: '9', type: 'item9' }
      ];
    }
  }

  saveState() {
    const state = {
      todo: this.todo,
      inProgress: this.inProgress,
      done: this.done
    };
    localStorage.setItem('dragDropState', JSON.stringify(state));
  }
  resetlayout(){
      localStorage.setItem('dragDropState', '');
      this.loadState();
  }
  job=[];
  is_loading=true;
  is_error='';
  getJob(jobid){
    this.jobService.getjobDetails(jobid).subscribe(
      data => {
       this.is_loading=false;
       if(data.data){
        this.job = data.data as string[];
        this.checkPermission(data.data.job_status)
        this.accounting(jobid)
       }

      },
      error=>{
        this.is_loading=false;
        this.is_error = error;
        console.log(error)
      }
    );
  }
  balance:any
  accounting(jobid){
      this.jobService.accounting(jobid).subscribe(
        data => {
          this.balance = data
        }
      );
  }

  // job/order notify
  onNotify(event):void{
    console.log("Ads",event.job_status)
    this.checkPermission(event.job_status)
  }
  permission = '';
  checkPermission(status){
    console.log('sd',status)
    if (status == 'Inspection' ||status == 'Work'|| status == 'Intake'||status == 'Completed' ) {
      // inspections_completed_sheet
      this.permission="allow";
    } else if (status == 'Cancelled') {
      // MAY 16TH 2023, CHANGING THIS TO "allow" AFTER
      // ADDING A WARNING ON CANCELLED INSPECTIONS
      this.permission="allow";
        // inspections_cancelled_confirmation
    } else {
      this.permission="permission_denied";
        // permission_denied
    }
    console.log('permission',this.permission)
  }

}
