import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule,NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule,ReactiveFormsModule }   from '@angular/forms';
import {NgxMaskModule} from 'ngx-mask';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import{ LockboxRoutingModule } from './lockbox-routing.module';
import { LockboxComponent } from './components/lockbox/lockbox.component';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule,
    AgmCoreModule,
    LockboxRoutingModule,
    NgbModule
  ],
  providers: [
    GoogleMapsAPIWrapper // <---
  ],
  declarations: [
    LockboxComponent
  ],
  entryComponents: [
    LockboxComponent
  ]
})
export class LockboxModule { }
