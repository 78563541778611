export class AgentSchedule {
    constructor(
        public agent_id : number,
        public from_date : any,
        public from_time : any,
        public to_time : any,
        public job_id : any
    ) {

    }
}