import { Component, OnInit } from '@angular/core';
import{ SharedService } from '../../services/shared.service'

@Component({
  selector: 'app-share-widgets',
  templateUrl: './share-widgets.component.html',
  styleUrls: ['./share-widgets.component.scss']
})
export class ShareWidgetsComponent implements OnInit {
	widgetList = [];
		
	selectedUsers = [];
	
	dropdownSettings = {};
	usersList = [];
	
	groupedWidgetTitle = '';
	groupedWidgetId = '';
	groupedWidgets = [];
	
	groupedWidgetValidation = false;
	groupedWidgetValidationMsg = false;
	
	groupList = [];
	
	showGroupAddForm = false;
	isGroupEditcase = false;

  	constructor(
  		private sharedService:SharedService,
  	) { }

  	ngOnInit() {
  		this.getWidgets();
		// console.log(this.groupedWidgets);

		this.getUsersList();
		this.getGroupWidgets();
		
		this.dropdownSettings = {
	      singleSelection: false,
	      idField: 'user_id',
	      textField: 'user_full_name',
	      selectAllText: 'Select All',
	      unSelectAllText: 'UnSelect All',
	      allowSearchFilter: true
	    };

  	}
  	checkGroupWidget(event,widgetId) {
		if(event.target.checked){
			if(!this.isGroupEditcase){
        this.showGroupAddForm = true;
			}
			this.groupedWidgets.push(widgetId);
		} else {
			for( var i = 0; i < this.groupedWidgets.length; i++){ 
				if (this.groupedWidgets[i] === widgetId) {
					this.groupedWidgets.splice(i, 1); 
				}
		 }
		}
	}
  	createNew() {
  		
  		this.clearGroupedWidgetFields();
  		this.showGroupAddForm = true;
  	}
  	getWidgets() {
		this.widgetList=[];
		this.sharedService.getWidgets().subscribe(
		  data=> {
			this.widgetList = data;
		  }
		)
		
		}
		
		getUsersList(){
			this.sharedService.getUsersList().subscribe(
				data=> {
					this.usersList = data;
			});	
		}

		clearGroupedWidgetFields(){
			this.selectedUsers = [];
			this.groupedWidgets = [];
			this.groupedWidgetId = ''; 
			this.groupedWidgetTitle = '';
			this.isGroupEditcase = false;
			this.showGroupAddForm = false;
		}
		
		saveGroupedWidgets() {
			this.groupedWidgetValidation = false;
			if(!this.groupedWidgetTitle) {
			  this.groupedWidgetValidation = true;
			}
			if(this.selectedUsers.length == 0) {
				this.groupedWidgetValidation = true;
			}
			if(this.groupedWidgets.length == 0) {
				this.groupedWidgetValidation = true;
			}
      		if(this.groupedWidgetValidation) {
				this.groupedWidgetValidationMsg = true;
				return false;
			} else {
				this.groupedWidgetValidationMsg = false;
			}

			if(!this.isGroupEditcase){
        		this.groupedWidgetId = '';
			}
			
			var groupWidgetParam = {
				'groupId' : this.groupedWidgetId,
				'groupTitle' : this.groupedWidgetTitle,
				'groupUsers' : this.selectedUsers,
				'groupedWidgets' : this.groupedWidgets
			} 

			console.log(groupWidgetParam);

			this.sharedService.postGroupedWidget(groupWidgetParam).subscribe(
				data=> {
					this.selectedUsers = [];
					this.groupedWidgets = [];
					this.groupedWidgetId = ''; 
					this.groupedWidgetTitle = '';
					this.showGroupAddForm = false;
					this.isGroupEditcase = false;
				  this.getGroupWidgets();
			});

			

		}	


		getGroupWidgets() {
			this.sharedService.getGroupedWidget().subscribe(
				data=> {
					this.groupList = data;
			});	
		}


		editWidgetGroup(groupId) {
			this.isGroupEditcase = true;
			this.selectedUsers = [];
			this.groupedWidgets = [];
			this.groupedWidgetId = ''; 
			this.groupedWidgetTitle = '';
			this.showGroupAddForm = false;
			this.sharedService.getSingleGroupData(groupId).subscribe(
				data=> {
					this.groupedWidgetId = data.id; 
				  	this.groupedWidgetTitle = data.group_title
					if(data.group_users) {
						for( var i = 0; i < data.group_users.length; i++){ 
							this.selectedUsers.push({user_full_name: data.group_users[i].user.user_first_name+' '+data.group_users[i].user.user_last_name,user_id: data.group_users[i].widget_user_id});
						}
					}
					if(data.widget_list) {
            			for( var i = 0; i < data.widget_list.length; i++){ 
							this.groupedWidgets.push(data.widget_list[i].widget_id);
						}
					}
					this.showGroupAddForm = true;
			});
		}
		removeWidgetGroup(id){
			var groupWidgetParam = {
				'id' : id
			}
			this.sharedService.postDeleteGroupWidget(groupWidgetParam).subscribe(
				data=> {
				  this.getGroupWidgets();
			});
		}

}
