import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
    providedIn: 'root'
})
export class FeesService {
    private apiURL = environment.api;
    constructor(private http: HttpClient) { }
    addFee(data): Observable<any> {
        return this.http.post(`${this.apiURL}fees/add`, data, httpOptions);
    }

    getAllFees(): Observable<any> {
        return this.http.get(this.apiURL + 'fees/getall');
    }

    getBranchList(): Observable<any> {
        return this.http.get(this.apiURL + 'fees/getallBranchList');
    }

    deleteFee(fee_id): Observable<any> {
        return this.http.get(this.apiURL + 'fees/delete/' + fee_id).pipe(
            map(res => res)
        )
    }

    getSingleFee(fee_id): Observable<any> {
        return this.http.get(`${this.apiURL}fees/getsingle/` + fee_id);
    }

    getJobAllFees(job_id): Observable<any> {
        return this.http.get(this.apiURL + 'fees/getjoball/' + job_id);
    }


}
