import { Component, OnInit, Input,OnChanges } from '@angular/core';
import {Router} from '@angular/router';
import {CommonService} from '../../services/common/common.service';
import {PayrollService} from '../../services/payroll/payroll.service';


import {NgbDateParserFormatter, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from "./../inspections-scheduled/ngb-date-fr-parser-formatter";

@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.component.html',
  providers: [{provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter},CommonService,PayrollService ],
  styleUrls: ['./payroll.component.scss']
})
export class PayrollComponent implements OnInit,OnChanges {
  @Input() count: number;
  public location = '' ;
  report = {
            branch:'',
            inspector:'',
            start:{},
            end:{},
            dateRange:0

          };
  branches=[];
  inspectors = [];
  inspectorCheck=false;
  reportData = [];
  total = [];
  noData=false;
  jobData=false;
  errordetails=false;
  loading=false;
  user={
        user_level:'',
        };

  // paymentTotalAmountCompletions = 0;
  // inspectorPaymentTotalAmountCompletions = 0;
  constructor(private  _router : Router,private calendar: NgbCalendar, private commonService: CommonService, private payrollService:PayrollService ) {
    this.location = _router.url;
   }

  ngOnInit() {
    this.getBranch();
    if(localStorage.getItem("user") != null){
      this.user = (JSON.parse(localStorage.getItem("user")));
      if(this.user.user_level=='inspector'){
        this.inspectorCheck=false;
      }else{
        this.inspectorCheck=true;
        this.getInspecotr();
      }
    }
  }
  ngOnChanges(){
    // date range check from dashboard
    if(this.count!=0){
      this.report.dateRange = this.count;
      this.dateRangeSubmit(this.count);
      this.payRollReport();
    }
  }
  payRollReport(){
    if(Object.keys(this.report.start).length == 0 || Object.keys(this.report.end).length == 0){
      this.errordetails=true;
      return false;
    }
    this.loading=true;
    this.payrollService.getPayRoll(this.report).subscribe(
      data => { 
                this.errordetails=false;
                this.loading=false;
                if(data.length<=0){
                  this.jobData=false;
                  this.noData =true;
                }else{
                  this.jobData=true;
                  this.noData =false;
                  
                  this.reportData = data;
                  console.log(this.reportData)

                  // var total = this.reportData;
                  
                  // this.total = data.totals;
                }
              }
    );
  }

  getBranch() {
    this.commonService.getBranch().subscribe(
      data => { 
                this.branches = data;
              }
    );
  }
  getInspecotr(){
    this.commonService.getInspectors().subscribe(
      data => { 
                this.inspectors = data;
              }
    );
  }
  clearPayRoll(){
    this.report = {
                      branch:'',
                      inspector:'',
                      start:{},
                      end:{},
                      dateRange:0
                    };
  }
  dateRangeSubmit(value ){
    this.report.end = this.calendar.getToday();
    this.report.start =this.commonService.dateRangeSubmit(value);
    // switch(value) {
    //   case '1':
    //       this.report.start = this.calendar.getPrev(this.calendar.getToday(), 'd', 30);
    //       break;
    //   case '2':
    //       this.report.start = this.calendar.getPrev(this.calendar.getToday(), 'd', 90);
    //       break;
    //   case '3':
    //       var date =(new Date()).getFullYear()
    //       this.report.start =  {year: date, month: 1, day: 1};
    //       break;
    //   case '4':
    //       var year =(new Date()).getFullYear()
    //       var date =(new Date()).getUTCMonth()
    //       this.report.start =  {year: year, month: date+1, day: 1};
    //       break;
    //   default:
    //       // code block
    // }  
  }

}
