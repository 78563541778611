import { Component, OnInit, EventEmitter, Input,OnChanges,HostListener   } from '@angular/core';
import {Router} from '@angular/router';
import {JobcostingService} from '../../services/jobcosting/jobcosting.service';
import {CommonService} from '../../services/common/common.service';
import {NgbDateParserFormatter, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from "./../inspections-scheduled/ngb-date-fr-parser-formatter";
import { environment } from '../../../../../environments/environment';



@Component({
  selector: 'app-jobcosting',
  templateUrl: './jobcosting.component.html',
  // providers: [ JobcostingService,CommonService ],
  providers: [{provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter},JobcostingService,CommonService],
  styleUrls: ['./jobcosting.component.scss']
})
export class JobcostingComponent implements OnChanges,OnInit   {
  @Input() count: number;
  public old_site;  
  public location = '' ;
  fixed = false;
  sticky_top = true;
  report = {
            branch:'',
            inspector:'',
            start:{},
            end:{},
            dateRange:0,
            notable_jobs:0,
            break_out_by_inspector:false
          };
  branches=[];
  inspectors = [];
  inspectorCheck=false;
  jobcost = [];
  total = [];
  noData=false;
  jobData=false;
  errordetails=false;
  loading=false;
  user={
    user_level:'',
        };
        
  constructor(private  _router : Router ,private calendar: NgbCalendar, private jobcostingService: JobcostingService, private commonService: CommonService) { 
    this.location = _router.url;
  }
  
  ngOnInit() {
    this.old_site = environment.apiSite;
    console.log(this.report);
    this.getBranch();
    if(localStorage.getItem("user") != null){
      this.user = (JSON.parse(localStorage.getItem("user")));
      if(this.user.user_level=='inspector'){
        this.inspectorCheck=false;
      }else{
        this.inspectorCheck=true;
        this.getInspecotr();
      }
    }
    // date range check from Local storage
    // if(localStorage.getItem("date_range") != null){
    //   var date = (JSON.parse(localStorage.getItem("date_range")));
    //   if(date.job_cost!=0){
    //     this.report.dateRange = date.job_cost;
    //     this.dateRangeSubmit(date.job_cost);
    //     this.jobCostingReport();
    //   } 
    // } 
    if(this.location === '/accounting-report-job-costing'){
      this.sticky_top = false;
    }

  }
  ngOnChanges(){
    // date range check from dashboard
    if(this.count!=0){
      this.report.dateRange = this.count;
      this.dateRangeSubmit(this.count);
      this.jobCostingReport();
    }
  }

  onScrollTb(event: Event) {
    // console.log(event);
    // console.log($(this).scrollLeft());
    // $('.actualheader').css('left', -$(this).scrollLeft() + "px");
  };
    
  @HostListener("window:scroll", [])
  onWindowScroll() {
    // console.log(this.location);
    // $('.actualheader').css('left', -$(this).scrollLeft() + "px");
    let top = window.scrollY;  
    if ( top > 299 ) {
      
      if(this.sticky_top === false){
        this.fixed = true;
        // console.log(this.fixed+'fixed');
      }

    } else {
        this.fixed = false;
    }

    let windowHeight = "innerHeight" in window ? window.innerHeight
             : document.documentElement.offsetHeight;
    let body = document.body, html = document.documentElement;
    let docHeight = Math.max(body.scrollHeight,
             body.offsetHeight, html.clientHeight,
             html.scrollHeight, html.offsetHeight);
    let windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight) {
      this.fixed = false;
    }
  }
  
  
  togglenotable(event) {
    if (event.target.checked ) {
      this.report.notable_jobs = 1;
    } else {
      this.report.notable_jobs = 0;
    }
}
    
    
  break_out_by_inspector;
  jobCostingReport() {
    // console.log(this.report);return 0
    if(Object.keys(this.report.start).length == 0 || Object.keys(this.report.end).length == 0){
        this.errordetails=true;
        return false;
    }
    this.loading=true;
    this.jobcostingService.getJobCost(this.report).subscribe(
      data => { 
              this.errordetails=false;
              this.loading=false;
              if(!data.jobs){
                this.jobData=false;
                this.noData =true;
              }else{
                this.jobData=true;
                this.noData =false;
                this.jobcost = data.jobs; 
                this.total = data.totals;
                this.break_out_by_inspector=data.break_out_by_inspector;
              }
            }
    );
    
  }
  clearJobCosting(){
    
    this.report = {
                    branch:'',
                    inspector:'',
                    start:{},
                    end:{},
                    dateRange:0,
                    notable_jobs:0,
                    break_out_by_inspector:false
                  };
  }
  dateRangeSubmit(value ){
    this.report.end = this.calendar.getToday();
    this.report.start =this.commonService.dateRangeSubmit(value);
  //   switch(value) {
  //     case '1':
  //         this.report.start = this.calendar.getPrev(this.calendar.getToday(), 'd', 30);
  //         break;
  //     case '2':
  //         this.report.start = this.calendar.getPrev(this.calendar.getToday(), 'd', 90);
  //         break;
  //     case '3':
  //         var date =(new Date()).getFullYear()
  //         this.report.start =  {year: date, month: 1, day: 1};
  //         break;
  //     case '4':
  //         var year =(new Date()).getFullYear()
  //         var date =(new Date()).getUTCMonth()
  //         this.report.start =  {year: year, month: date+1, day: 1};
  //         break;
  //     default:
  //         // code block
  // }  
    
  
  }
  getBranch() {
    this.commonService.getBranch().subscribe(
      data => { 
        this.branches = data;
      }
    );
  }
  getInspecotr(){
    this.commonService.getInspectors().subscribe(
      data => { 
                this.inspectors = data;
              }
    );
  }
  


  

}
