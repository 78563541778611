import { Component, OnInit } from '@angular/core';
import {NgbDateParserFormatter, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from "./../inspections-scheduled/ngb-date-fr-parser-formatter";
import {CommonService} from '../../services/common/common.service';
import {ReportService} from '../../services/report/report.service';

@Component({
  selector: 'app-agent-activity',
  templateUrl: './agent-activity.component.html',
  styleUrls: ['./agent-activity.component.scss'],
  providers: [{provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter},CommonService ],
})
export class AgentActivityComponent implements OnInit {
  report = {
    branch:'',
    start:{},
    end:{},
    min_inspections:'',
    min_completions:'',
    page:1
  };
  branches=[];
  errordetails='';
  agent=[];
  loderDivShow = false;
  constructor(private agentReport: ReportService, private calendar: NgbCalendar, private commonService: CommonService) { }
  
  ngOnInit() {
    this.getBranch();
  }
  getBranch() {
    this.commonService.getBranch().subscribe(
      data => { 
                this.branches = data;
              }
    );
  }
  pages=[];
  numbers=[];
  agentActivityReport(value){
    this.loderDivShow = true;
    this.report.page= value;
    this.agentReport.agentActivityReport(this.report).subscribe(
      data => { 
                this.loderDivShow = false;
                this.errordetails='';
                if(data.data.client){
                  this.agent = data.data.client;
                }else{
                  this.errordetails='No Data';
                }    
                this.report.page= data.data.page.current_page-3
                this.pages=data.data.page;
                this.numbers.length=0;
                this.numbers = Array(7).fill(this.report.page).map((x,i)=>i+x);
              },
      error =>{
                this.loderDivShow = false;
                this.errordetails = error; 
                 console.log(error);
              }
    );
  }
  clearActivity(){
    this.report = {
                      branch:'',
                      start:{},
                      end:{},
                      min_inspections:'',
                      min_completions:'',
                      page:1
                    };
  }
  // paginate(value){
  //   this.agentActivityReport(value);
  // }

}
