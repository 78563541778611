import { Component, OnInit, ViewChild } from '@angular/core';
import { Replay } from '../replay';
import { SettingService } from '../../../services/setting.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-canned-reply',
  templateUrl: './edit-canned-reply.component.html',
  styleUrls: ['./edit-canned-reply.component.scss']
})
export class EditCannedReplyComponent implements OnInit {

	refreshTrigger: number = 0;
	@ViewChild('tinymce') tinymce;
	id = null;
	billingFooter: any = {
	    data: ''
	};
	showForm = false;
	submitted = false;
	saveStatus = "";

	replay = new Replay(null, null, null, null, true);
  	constructor(
  		private settingService: SettingService,
  		private _router: Router,
        private router: ActivatedRoute,
  	) { }

  	ngOnInit() {
  		this.router.params.subscribe(params => {
            if (params.id) {
                this.id = params.id;
                this.getReply(this.id);
            }
        })
  	}
  	getReply(id){
  		this.settingService.getReply(id).subscribe(
	      data => {
	        	this.replay = data;
	      }
	    );
  	}
  	clientSubmit(clientForm){
  		this.submitted = true;
  		this.settingService.updateReply(this.id, this.replay).subscribe(
        data => {
          	this.submitted = false;
          	this.saveStatus = 'Saved';
          	clientForm.reset();
          	Swal({
	            title: 'Success',
	            text: 'update successfully'
        	})
        	this._router.navigate(['canned-replies']);
        }
      );
  	}

}
