import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { SettingService } from '../../services/setting.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-canned-replies',
  templateUrl: './canned-replies.component.html',
  styleUrls: ['./canned-replies.component.scss']
})
export class CannedRepliesComponent implements OnInit, OnChanges {

	@Input() refreshTrigger!: number;
  	listData=[];
  	noData = false;
 	constructor(private settingService: SettingService) {

  	}

  	ngOnChanges() {
	    this.loadReplies();
	  }

  	ngOnInit() {
	    this.loadReplies();
	}

	loadReplies() {
	    this.settingService.getReplies().subscribe(
	      data => {
	        	this.listData = data;
	        	console.log(this.listData)
              	if(data.length>0){
                	this.noData=false;
              	} else{
                	this.noData=true;
              	}
	      }
	    );
	 }


	 deleteReply(id) {
        Swal({
            title: 'Are you sure?',
            text: 'Delete this!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                this.settingService.deleteReply(id).subscribe(
                    data => {
                        this.loadReplies();
                        Swal(
                            'Deleted!',
                            'Fee has been deleted.',
                            'success'
                        )
                    }
                );

            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        })
    }

}
