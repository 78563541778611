import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router';
import {CommonService} from '../../services/common/common.service';
import {PayrollService} from '../../services/payroll/payroll.service';

import {NgbDateParserFormatter, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from "./../inspections-scheduled/ngb-date-fr-parser-formatter";
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-accounting-report-payroll',
  templateUrl: './accounting-report-payroll.component.html',
  providers: [{provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter},CommonService,PayrollService ],
  styleUrls: ['./accounting-report-payroll.component.scss']
})
export class AccountingReportPayrollComponent implements OnInit {
  @Input() count: number;
  public location = '' ;
  public old_site; 
  report = {
            branch:'',
            inspector:'',
            start:{},
            end:{},
            dateRange:0

          };
  branches=[];
  inspectors = [];
  inspectorCheck=false;
  reportData = [];
  total = [];
  noData=false;
  jobData=false;
  errordetails=false;
  loading=false;
  user={
          user_level:'',
        };
                
  constructor(private  _router : Router,private calendar: NgbCalendar, private commonService: CommonService, private payrollService:PayrollService ) { 
    this.location = _router.url;  
  }

  ngOnInit() {
    this.old_site = environment.apiSite;
    this.getBranch();
    if(localStorage.getItem("user") != null){
      this.user = (JSON.parse(localStorage.getItem("user")));
      if(this.user.user_level=='inspector'){
        this.inspectorCheck=false;
      }else{
        this.inspectorCheck=true;
        this.getInspecotr();
      }
    }
  }

  ngOnChanges(){
    // date range check from dashboard
    if(this.count!=0){
      this.report.dateRange = this.count;
      this.dateRangeSubmit(this.count);
      this.payRollReport();
    }
  }

  payRollReport(){
    if(Object.keys(this.report.start).length == 0 || Object.keys(this.report.end).length == 0){
      this.errordetails=true;
      return false;
    }
    this.loading=true;
    // this.payrollService.getPayRoll(this.report).subscribe(
    this.payrollService.getPayRollNew(this.report).subscribe(
      data => { 
                this.errordetails=false;
                this.loading=false;
                if(data.length<=0){
                  this.jobData=false;
                  this.noData =true;
                }else{
                  this.jobData=true;
                  this.noData =false;
                  
                  this.reportData = data;
                }
              }
    );
  }

  getBranch() {
    this.commonService.getBranch().subscribe(
      data => { 
                this.branches = data;
              }
    );
  }
  getInspecotr(){
    this.commonService.getInspectors().subscribe(
      data => { 
                this.inspectors = data;
              }
    );
  }
  clearPayRoll(){
    this.report = {
                      branch:'',
                      inspector:'',
                      start:{},
                      end:{},
                      dateRange:0
                    };
  }
  dateRangeSubmit(value ){
    this.report.end = this.calendar.getToday();
    this.report.start =this.commonService.dateRangeSubmit(value);  
  }

}
