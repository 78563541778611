import { Component, OnInit, Input, OnChanges } from '@angular/core';
import {Router} from '@angular/router';
import { NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from "./ngb-date-fr-parser-formatter"
import { CommonService } from '../../services/common/common.service'; 
import { ReportService } from '../../services/report/report.service'; 
import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-inspections-scheduled',
  templateUrl: './inspections-scheduled.component.html',
  styleUrls: ['./inspections-scheduled.component.scss'],
  providers: [{provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter}]
})
export class InspectionsScheduledComponent implements OnInit,OnChanges {
  @Input() count: number;
  public show:boolean = false;
  account_schedule_page_datas=false;
  public location = '';
  public dateData = {}

  
  public pieChartLabelsScheduled:string[] = [];
  public pieChartDataScheduled:number[] = [];
  
  public pieChartLabelsPerformed:string[] = [];
  public pieChartDataPerformed:number[] = [];
  
  public pieChartType:string = 'pie';

  user={
    user_level:'',
        };
  
  branches=[];
  inspectors = [];
  inspectorCheck=false;
  report = {
    branch:'',
    inspector:'',
    start:{},
    end:{},
    dateRange:0
  }; 
  errordetails=false;
  loading=false;
  head_data = [];
  table_data=[];
  graph_data=[];


  constructor(private _router : Router,private calendar: NgbCalendar, private commonService : CommonService, private reportService : ReportService) { 
    this.location = _router.url;
  }
  
  ngOnInit() {
    this.getBranch();
    if(localStorage.getItem("user") != null){
      this.user = (JSON.parse(localStorage.getItem("user")));
      if(this.user.user_level=='inspector'){
        this.inspectorCheck=false;
      }else{
        this.inspectorCheck=true;
        this.getInspecotr();
      }
    }
  }
  ngOnChanges(){
    // date range check from dashboard
    if(this.count!=0){
      this.report.dateRange = this.count;
      this.dateRangeSubmit(this.count);
      this.inspectionReport();
    }
  }
  
  inspectionReport() {
    if(Object.keys(this.report.start).length == 0 || Object.keys(this.report.end).length == 0){
        this.errordetails=true;
        return false;
    }
    this.errordetails=false;
    this.loading=true;
    this.reportService.getInspections(this.report).subscribe(
      data => {
        this.loading=false;
        this.account_schedule_page_datas=true;
        this.head_data = data.data.results.head_data;
        this.table_data = data.data.results.table_data;
        
        this.pieChartDataScheduled = data.data.results.graph_data.scheduled.value;  
        this.pieChartDataPerformed = data.data.results.graph_data.performed.value;
        
        setTimeout( () => {
          this.pieChartLabelsScheduled = data.data.results.graph_data.scheduled.label;
          this.pieChartLabelsPerformed = data.data.results.graph_data.performed.label;
        });
        
          
      }
    );
    
  }
  clear(){
    this.report = {
                    branch:'',
                    inspector:'',
                    start:{},
                    end:{},
                    dateRange:0

                  };
  }
  dateRangeSubmit(value ){
    this.report.end = this.calendar.getToday();
    this.report.start =this.commonService.dateRangeSubmit(value);
  //   switch(value) {
  //     case '1':
  //         this.report.start = this.calendar.getPrev(this.calendar.getToday(), 'd', 30);
  //         break;
  //     case '2':
  //         this.report.start = this.calendar.getPrev(this.calendar.getToday(), 'd', 90);
  //         break;
  //     case '3':
  //         var date =(new Date()).getFullYear()
  //         this.report.start =  {year: date, month: 1, day: 1};
  //         break;
  //     case '4':
  //         var year =(new Date()).getFullYear()
  //         var date =(new Date()).getUTCMonth()
  //         this.report.start =  {year: year, month: date+1, day: 1};
  //         break;
  //     default:
          
  // }  
    
  
  }
  getBranch() {
    this.commonService.getBranch().subscribe(
      data => { 
              this.branches = data;
            }
    );
  }
  getInspecotr(){
    this.commonService.getInspectors().subscribe(
      data => { 
                this.inspectors = data;
              }
    );
  }
  toggle() {
    // console.log('ssas')
    this.show = !this.show;
  }
 

}
