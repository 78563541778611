import { Component, OnInit,Output,Input, EventEmitter } from '@angular/core';
import{ SharedService } from '../../services/shared.service'
@Component({
  selector: 'app-sotable-widget',
  templateUrl: './sotable-widget.component.html',
  styleUrls: ['./sotable-widget.component.scss']
})
export class SotableWidgetComponent implements OnInit {
  @Output () notify: EventEmitter<string> = new EventEmitter<string>();
  widgetList = [];
  listStyle = {
    width:'500px', //width of the list defaults to 300
    height: '400px', //height of the list defaults to 250
  }
  
  constructor(private sharedService:SharedService,) { }

  ngOnInit() {
    this.getWidgets();
    
  }
  getWidgets() {
		this.widgetList=[];
		this.sharedService.getWidgets().subscribe(
		  data=> {
        this.widgetList = data;
		  }
		)
		
  }
  listOrderChanged(event){
 
      this.sharedService.reOrderWidgets(event).subscribe(
        data=> {
        // this.widgetList = data;
          if(data){
            this.notify.emit(data);
          }    
        }
      )
      
      

  }

}
