import { Component, OnInit,Input } from '@angular/core';
// import { FormBuilder,Validators } from '@angular/forms';
import { JobService } from '../../services/job/job.service';
import { NgbActiveModal,NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-job-proofed',
  templateUrl: './job-proofed.component.html',
  styleUrls: ['./job-proofed.component.scss']
})
export class JobProofedComponent implements OnInit {
  @Input() jobData
  title = "Mark Job as proofed";
  proofed_status = "";
  constructor(
    public jobService:JobService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.proofed_status = this.jobData.proofed_status==1 ? "yes" : "no"
  }
  statusSubmit(){
    this.jobService.changeJobProofedStatus({proofed_status : this.proofed_status} ,this.jobData.job_id).subscribe(
      data => {
        this.activeModal.close(data.data);
      }
    );
  }
 

}
