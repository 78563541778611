import {Component, Input, OnInit} from '@angular/core';
import {SettingService} from '../../../../../services/setting.service';

@Component({
  selector: 'app-intake-header',
  templateUrl: './intake-header.component.html',
  styleUrls: ['./intake-header.component.scss']
})
export class IntakeHeaderComponent implements OnInit {
  @Input() jobId: string;
  @Input() location: string;
  @Input() tab: boolean;
  @Input() rounded: boolean;

  inspector: boolean;

  tabs_order = [];
  tab_links = {
    'client': 'inspections-order-parties',
    'property': 'inspections-edit-property',
    'scheduling': 'inspections-schedule',
  };
  tab_labels = {
    'client': 'Client',
    'property': 'Property',
    'scheduling': 'Scheduling',
  };

  constructor(private settingService: SettingService) {
  }

  ngOnInit() {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData.user_level === 'inspector') {
      this.inspector = false;
      this.tab = true;
    } else {
      this.inspector = true;

      if (localStorage.getItem('tab') != null && this.jobId !== '') {
        this.tab = (JSON.parse(localStorage.getItem('tab')));
      }
      // this.tab=false;
    }
    this.settingService.getSettingData().subscribe(
      data => {
        const tabOrders = {};
        for (const tab of data.tabs_order) {
          if (this.inspector) {
            this.tabs_order.push(tab);
            tabOrders[tab] = this.tab_links[tab];
          }
        }
        localStorage.setItem('tab_orders', JSON.stringify(tabOrders));
      }
    );
  }

  displayTab(data) {
    this.tab = data;
    localStorage.setItem('tab', JSON.stringify(this.tab));
  }
}
