import { Component, OnInit } from '@angular/core';
import{ SharedService } from '../../services/shared.service';
// import {CommonService} from '../../../modules/reports/services/common/common.service';
import { FormGroup, FormControl,  FormBuilder } from '@angular/forms';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-wants-earlier',
  templateUrl: './wants-earlier.component.html',
  styleUrls: ['./wants-earlier.component.scss']
})
export class WantsEarlierComponent implements OnInit {
  public old_site;
  wantsForm = this.fb.group({
    branch: [''],
  });
  wantsEarlier=[];
  branches=[];
  noData=false;
  displayData=false;
  constructor(	private sharedService:SharedService, private fb: FormBuilder ) { }
  ngOnInit() {
    this.getBranch();
    this.onSubmit();
    this.old_site = environment.apiSite;
  }
  getBranch() {
    this.sharedService.getBranch().subscribe(
      data => { 
              this.branches = data;
            }
    );
  }
  
  onSubmit(){
    this.displayData=true;
    // console.warn(this.wantsForm.value)
    this.sharedService.wantsEarlier(this.wantsForm.value).subscribe(
      data => { 
              // this.branches = data;
              this.wantsEarlier=data;
              if(data.length>0){
                this.noData=false;
              } else{
                this.noData=true;
              }
            }
    );
  }
  route(link){
    console.log(this.old_site)
    window.location.href = this.old_site.url+'/inspections_completed_sheet/'+link; 
  }
  reset(){
    // this.wantsForm.reset()
    this.wantsForm.patchValue({
      branch: ''
    });
  }

}
