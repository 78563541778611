import { Component, OnInit, Input,OnChanges} from '@angular/core';
import {Router} from '@angular/router';
import {EstimatedActualService} from '../../services/estimated-actual/estimated-actual.service';
import {CommonService} from '../../services/common/common.service';
import {NgbDateParserFormatter, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from "./../inspections-scheduled/ngb-date-fr-parser-formatter";

@Component({
  selector: 'app-estimated-actual',
  templateUrl: './estimated-actual.component.html',
  providers: [{provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter},CommonService, EstimatedActualService],
  styleUrls: ['./estimated-actual.component.scss']
})
export class EstimatedActualComponent implements OnInit,OnChanges {
  @Input() count: number;
  public location = '' ;
  report = {
            branch:'',
            inspector:'',
            active: true,
            start:{},
            end:{},
            dateRange:0

          };
  branches=[];
  inspectors = [];
  inspectorCheck=false;
  reportData = [];
  total = [];
  noData=false;
  jobData=false;
  errordetails=false;
  loading=false;
  user={
    user_level:'',
        };
  
  constructor(private  _router : Router ,private calendar: NgbCalendar, private estimateService: EstimatedActualService, private commonService: CommonService) { 
    this.location = _router.url;
  }
 
  ngOnInit() {
    this.getBranch();
    if(localStorage.getItem("user") != null){
      this.user = (JSON.parse(localStorage.getItem("user")));
      if(this.user.user_level=='inspector'){
        this.inspectorCheck=false;
      }else{
        this.inspectorCheck=true;
        this.getInspecotr();
      }
    }
  }
  ngOnChanges(){
    // date range check from dashboard
    if(this.count!=0){
      this.report.dateRange = this.count;
      this.dateRangeSubmit(this.count);
      this.estimateReport();
    }
  }
  estimateReport() {
    console.log(this.report);
    if(Object.keys(this.report.start).length == 0 || Object.keys(this.report.end).length == 0){
        this.errordetails=true;
        return false;
    }
    this.loading=true;
    this.estimateService.getEstimated(this.report).subscribe(
      data => { 
        console.log(data)
              this.errordetails=false;
              this.loading=false;
              if(data){
                
                this.reportData = data;
              }
              // if(!data.jobs){
              //   this.noData =true;
              // }else{
              //   this.noData =false;
              // }


            }

    );
    
  }
  clear(){
    this.report = {
                    branch:'',
                    inspector:'',
                    active:false,
                    start:{},
                    end:{},
                    dateRange:0

                  };
  }

  getBranch() {
    this.commonService.getBranch().subscribe(
      data => { 
              this.branches = data;
            }
    );
  }
  getInspecotr(){
    this.commonService.getInspectors().subscribe(
      data => { 
                this.inspectors = data;
              }
    );
  }
  dateRangeSubmit(value ){
    this.report.end = this.calendar.getToday();
    this.report.start =this.commonService.dateRangeSubmit(value);
    // switch(value) {
    //   case '1':
    //       this.report.start = this.calendar.getPrev(this.calendar.getToday(), 'd', 30);
    //       break;
    //   case '2':
    //       this.report.start = this.calendar.getPrev(this.calendar.getToday(), 'd', 90);
    //       break;
    //   case '3':
    //       var date =(new Date()).getFullYear()
    //       this.report.start =  {year: date, month: 1, day: 1};
    //       break;
    //   case '4':
    //       var year =(new Date()).getFullYear()
    //       var date =(new Date()).getUTCMonth()
    //       this.report.start =  {year: year, month: date+1, day: 1};
    //       break;    
    //   default:
    //       // code block
    // }  
  }
}
