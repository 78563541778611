import { Component, OnInit } from '@angular/core';
import {NgbDateParserFormatter, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from "./../inspections-scheduled/ngb-date-fr-parser-formatter";
import {CommonService} from '../../services/common/common.service';
import {ReportService} from '../../services/report/report.service';
@Component({
  selector: 'app-inspectors-sales',
  templateUrl: './inspectors-sales.component.html',
  styleUrls: ['./inspectors-sales.component.scss'],
  providers: [{provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter},CommonService ],
})
export class InspectorsSalesComponent implements OnInit {
  report = {
    branch:'',
    inspector:'',
    start:{},
    end:{},
  };
  branches=[];
  inspectors = [];
  errordetails='';
  sales=[];
  loderDivShow = false;
  rows = [
    { name: '', inspections: '', reinspections: '', completions: '', percentage: '', completion: '', total: '',perComplete: '',perInspection: '',},
    
  ];
  columns = [
    { name: 'name', 'prop' : 'name' },
    { name: 'Inspections', 'prop' : 'inspections' },
    { name: 'Reinspections', 'prop' : 'reinspections' },
    { name: 'Completions', 'prop' : 'completions' },
    { name: '%(Percentage)', 'prop' : 'percentage' },
    { name: 'Completion $', 'prop' : 'completion' },
    { name: 'Total $', 'prop' : 'total' },
    { name: '$ Per Complete', 'prop' : 'perComplete' },
    { name: '$ Per Inspection', 'prop' : 'perInspection' },
  ];
  constructor(private salesReport: ReportService, private calendar: NgbCalendar, private commonService: CommonService) { }

  ngOnInit() {
    this.getBranch();
    this.getInspecotr();
  }
  getBranch() {
    this.commonService.getBranch().subscribe(
      data => { 
                this.branches = data;
              }
    );
  }
  getInspecotr(){
    this.commonService.getInspectors().subscribe(
      data => { 
                this.inspectors = data;
              }
    );
  }
  clearActivity(){
    this.report = {
                      branch:'',
                      inspector:'',
                      start:{},
                      end:{},
                    };
  }
  inspectorSalesReport(){
    this.loderDivShow = true;
    this.salesReport.inspectorSalesReport(this.report).subscribe(
      data => { 
                this.loderDivShow = false;
                this.errordetails='';
                if(data.data.inspector){
                  this.sales = data.data.inspector;
                  this.rows=[...this.sales];
                  console.log(this.rows)
                }else{
                  this.errordetails='No Data';
                }    
               
              },
      error =>{
                this.loderDivShow = false;
                this.errordetails = error; 
                 console.log(error);
              }
    );
  }

}
