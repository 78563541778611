import { Component, OnInit, Input ,ViewChild} from '@angular/core';
import { NgbActiveModal,NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import {Widget} from '../../widget';
import{SharedService} from '../../services/shared.service';
import {CommonService} from '../../../modules/reports/services/common/common.service';
import { NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from "../../../modules/reports/component/inspections-scheduled/ngb-date-fr-parser-formatter";
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';


// import {Observable} from 'rxjs';
// import {debounceTime, map} from 'rxjs/operators';

import {Observable, Subject, merge} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';


@Component({
  selector: 'app-quick-widget-form-modal',
  templateUrl: './quick-widget-form-modal.component.html',
  styleUrls: ['./quick-widget-form-modal.component.scss'],
  providers: [{provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter}]
})
export class QuickWidgetFormModalComponent implements OnInit   {
	branches=[];
	inspectors = [];
	dataSource = [];
	googWidgets = [
		'google_sheet_SacramentoHome_C2',
		'google_sheet_SacramentoHome_D2',
		'google_sheet_SacramentoPest_C2',
		'google_sheet_SacramentoPest_D2',
		'google_sheet_AuburnHome_C2',
		'google_sheet_AuburnHome_D2',
		'google_sheet_AuburnPest_C2',
        'google_sheet_AuburnPest_D2',
		'google_sheet_VacavilleHome_C2',
		'google_sheet_VacavilleHome_D2',
		'google_sheet_VacavillePest_C2',
		'google_sheet_VacavillePest_D2',
		'google_sheet_SanDiegoHome_C2',
		'google_sheet_SanDiegoHome_D2',
		'google_sheet_SanDiegoPest_C2',
		'google_sheet_SanDiegoPest_D2',
		'google_sheet_DashboardTotals_B2',
		'google_sheet_DashboardTotals_B4',
		'google_sheet_DashboardTotals_B6',
		'google_sheet_DashboardTotals_B8'
	];
	modal_title="Add Widget";
	dropdownSettings = {};
	selectedSources = [];
	// public model: any;
	public error='OK';
	date_range=false;
	@Input() widget_id;
	widget = new Widget(null,'','',0,0,null,'',null,null,null,null);
	inspectorCheck=false;
	user={
		user_level:'',
	};
	
  	constructor(
		  public activeModal: NgbActiveModal,
		  private sharedServices:SharedService,
		  private commonService:CommonService,
		  private calendar: NgbCalendar,
	) { }
	ngOnInit() {
		this.getBranch();
		if(localStorage.getItem("user") != null){
		this.user = (JSON.parse(localStorage.getItem("user")));
		if(this.user.user_level=='inspector'){
			this.inspectorCheck=false;
		}else{
			this.inspectorCheck=true;
			this.getInspecotr();
		}
		}
		this.dataSources();
		
		this.widget.is_today='1';

		this.dropdownSettings = {
	      singleSelection: false,
	      idField: 'id',
	      textField: 'name',
	      selectAllText: 'Select All',
	      unSelectAllText: 'UnSelect All',
	      allowSearchFilter: true
	    };
	}
	
	changeDate(val){
		this.date_range=val;
	}

	// powers = [0,1,2];

	// widget = new Widget('',0,0,0,null,'',1);

	submitted = false;
	closeModal() {
		this.activeModal.close('Modal Closed');
	}

	widgetSubmit() { 
		this.submitted = false;
		this.widget.data_source_id = this.selectedSources;
		if(this.selectedSources.length > 0){
			this.sharedServices.addWidget(this.widget).subscribe(
				data => {
					this.submitted = false;
					this.closeModal();
				},
				error =>  this.error=error
			);
		} else {
			this.submitted = true;
		}
			
	}
	
	getBranch() {
		this.commonService.getBranch().subscribe(
		  data => { 
					// console.log(data);
				  this.branches = data;
				}
		);
	  }
	getInspecotr(){
	this.commonService.getInspectors().subscribe(
		data => { 
			
				this.inspectors = data;
			}
	);
	}
	dataSources(){
		this.sharedServices.getdataSources().subscribe(
			data => { 
				this.dataSource = data;
				if(typeof(this.widget_id) == 'number'){
					this.newWidget(this.widget_id);
					this.modal_title="Edit Widget";
				}
			}
		);
	}
	is_loading;
	newWidget(id) {
		this.is_loading=true;
		this.sharedServices.editWidget(id).subscribe(
			data => {

					this.selectedSources = [];
					this.is_loading=false;
					// this.dataSourceChange(data['data_source_id']);

					this.widget = new Widget(id,data['title'],data['data_source_id'],data['branch_id'],data['agent_id'],data['goal'],data['data_type'],data['is_today'],data['from_date'],data['to_date'],data['goal_completed']);
					if(data.widget_sources) {
						for( var i = 0; i < data.widget_sources.length; i++){ 
							this.selectedSources.push(
								{	
									id: data.widget_sources[i].data_source_id,
									name: data.widget_sources[i].data_source.name,
									slug: data.widget_sources[i].data_source.slug
								}
							);
						}
					}
					this.onItemSelect(data['data_source_id'])
					if(data['is_today']==0){
						this.date_range=true;
						this.widget.is_today='0';
					}else{
						this.widget.is_today='1';
					}
					
					},
					error =>  this.error=error
		  );

	
	}
	dateRangeSubmit(value ){
		this.widget.to_date = this.calendar.getToday();
		switch(value) {
		  case '1':
			  this.widget.from_date = this.calendar.getPrev(this.calendar.getToday(), 'd', 30);
			  break;
		  case '2':
			  this.widget.from_date = this.calendar.getPrev(this.calendar.getToday(), 'd', 90);
			  break;
		  case '3':
			  var date =(new Date()).getFullYear()
			  this.widget.from_date =  {year: date, month: 1, day: 1};
		  break;
		  default:
			  // code block
	  }
	}
	sourceSlugs = [];
	isJustCustom = false;
	onItemSelect(event){
		console.log(event);
		console.log(this.selectedSources);
		this.sourceSlugs = [];
		this.isJustCustom = false;
		for( var i = 0; i < this.dataSource.length; i++){ 
			var id = this.dataSource[i]['id'];
			for( var k = 0; k < this.selectedSources.length; k++){ 
				var idK = this.selectedSources[k]['id'];
				if(idK == id){
					this.sourceSlugs.push(this.dataSource[i]['slug'])
				}
			}
		}
		if(this.sourceSlugs.length == 1 && this.sourceSlugs[0] == 'custom'){
			this.isJustCustom = true;
		} 
		console.log(this.sourceSlugs);
	}
	// checkDataSource;
	// dataSourceChange(event){
	// 	if(event){
	// 		let result = this.dataSource.find( x => parseInt(x['id']) == parseInt(event) );
			
	// 		if(result && result['slug']){
	// 			this.checkDataSource = result['slug']
	// 		}	
	// 	}
		
	// }

}

