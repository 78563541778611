export class Company {
    constructor(
        public company_old_id:number,
        public company_id:any,
        public company_reference_id: any,
        public company_type: any,
        public company_name: any,
        public company_address1 :  string,
        public company_address2 :  string,
        public company_city :  string,
        public company_state :  any,
        public company_zip :  string,
        public company_phone :  any,

      ) {  }
    
}
