import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal,NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import {CommonService} from '../../../intake/services/common/common.service';
import { PropertyService } from '../../../intake/services/property/property.service';

@Component({
  selector: 'app-lockbox',
  templateUrl: './lockbox.component.html',
  styleUrls: ['./lockbox.component.scss']
})
export class LockboxComponent implements OnInit {
  modal_title="";
  lockbox_code='';
  is_verified=false;
  lockbox_error='';
  lockboxCode="";
  propertyId:any;
  constructor(
    private commonService: CommonService,
    private propertyService: PropertyService,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    
  }
  closeModal() {
		this.activeModal.close(this.lockboxCode);
  }
  setLockboxCode(event){
    if(this.propertyId){
      this.propertyService.setLockboxCode({'lockbox_code' : this.lockbox_code, 'property_id' : this.propertyId}).subscribe(data=>{
        if(data.success) {
          this.activeModal.close(this.lockbox_code);
        }
        else {
          this.lockbox_error = data.message;
          this.lockboxCode = "";
        }
      });
    }
    else {
      this.lockboxCode="";
    }
  }

}
