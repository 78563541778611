import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal,NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from "../../../reports/component/inspections-scheduled/ngb-date-fr-parser-formatter";
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

import { CompletionService } from '../../services/completion/completion.service';
import { environment } from '../../../../../environments/environment';
import { id } from '@swimlane/ngx-datatable/release/utils';

@Component({
  selector: 'app-credit-card-fees',
  templateUrl: './credit-card-fees.component.html',
  styleUrls: ['./credit-card-fees.component.scss'],
  providers: [{ provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }]
})
export class CreditCardFeesComponent implements OnInit {
	@Input() job_id;
  	@Input() status;
  	@Input() id;
  
	states: string[];
	year = [];

	search = false;
	edit_credit_cc_fees: boolean = false;

	creditCardFeeForm: FormGroup;
	submitted = false;
	editCreditCardFeeForm: FormGroup;
	submittedEditForm = false;

	
	creditCardFees : any = {};
	creditCardFeesDateCompleted : any = '';
	constructor(
		public activeModal: NgbActiveModal,
	    private calendar: NgbCalendar,
	    private formBuilder: FormBuilder,
	    private completionService : CompletionService
    ) { }

	ngOnInit() {
		if(this.status == 'edit')
	    {
	      this.edit_credit_cc_fees = true;
	      this.getCreditCardFees(this.id);
	    }
	    this.creditCardFeeForm = this.formBuilder.group({
	      cc_charge: ['', Validators.required],
	      cc_notes : [''],
	      cc_charge_date : ['', Validators.required]
	    });
	    this.editCreditCardFeeForm = this.formBuilder.group({
	      cc_charge_date_edit : ['']
	    });
	    this.getYear();
	}

	get f(){ 
		return this.creditCardFeeForm.controls; 
	}

	getYear() {
		var d = new Date();
		var n = d.getFullYear();
		console.log(n);
		for (var i = 0; i < 50; i++) {
		  this.year.push(n + i)
		}
	}
	onDateSelect(event) {
		this.search = true;
	// this.setCalendarStatus();
	// this.searchSchedules();
	}

	onSubmit(){
	    this.submitted = true;
	    if(this.creditCardFeeForm.invalid){
	      return;
	    }
	    this.creditCardFeeForm.value.job_id = this.job_id;
	    this.completionService.addCreditCardFees(this.creditCardFeeForm.value).subscribe(
	      data => {
	        if(data){
	          Swal(
	            'Success!',
	            data.message,
	            data.status
	          );
	          
	          this.activeModal.close({data})
	        }
	      }
	    )
	}

	getCreditCardFees(id){
    this.completionService.getCreditCardFees(id).subscribe(
      data => {
        if(data){
          this.creditCardFees = data;
          let date = this.creditCardFees.cc_charge_date;
          if(date != ''){
            let search = date.search(' ');
            let dateOnly = date.slice(0,search);
            let dateArr = dateOnly.split('-');
            this.creditCardFeesDateCompleted = `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`;
          }
        }
      }
    )
  }
	onEditFormSubmit(){
	    if( this.editCreditCardFeeForm.value.cc_charge_date_edit == '' ){
	      let date = this.creditCardFeesDateCompleted;
	      let dateArr = date.split('/');

	      this.editCreditCardFeeForm.value.cc_charge_date_edit = 
	      { year : dateArr[2], month: dateArr[0], day : dateArr[1] };
	      // `${this.findingAdditionalWorkDateCompleted} 00:00:00`;
	    }
	    this.editCreditCardFeeForm.value.id = this.id;
	    this.editCreditCardFeeForm.value.job_id = this.job_id;
	    this.completionService.updateCreditCardFees(this.editCreditCardFeeForm.value).subscribe(
	      data => {
	        if(data){
	          console.log(data)
	          Swal(
	            'Success!',
	            data.message,
	            'success'
	          )
	          this.activeModal.close({data})
	        }
	      }
	    )
	}

}
