import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class EstimatedActualService {

  constructor(private http: HttpClient) { }

  getEstimated (data): Observable<any> {
    return this.http.post<any>(environment.api+'estimated_report', data, httpOptions)
      .pipe(
        map(res=>res)
      );
  }

  
}
