import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inspections-order-parties',
  templateUrl: './inspections-order-parties.component.html',
  styleUrls: ['./inspections-order-parties.component.scss']
})
export class InspectionsOrderPartiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
