import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule }   from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { BrowserModule } from '@angular/platform-browser';

import { DataTablesModule } from 'angular-datatables';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { ReportsRoutingModule } from './reports-routing.module';
import { JobcostingComponent } from './component/jobcosting/jobcosting.component';
import { InspectionsScheduledComponent } from './component/inspections-scheduled/inspections-scheduled.component';
import { PayrollComponent } from './component/payroll/payroll.component';
import { EstimatedActualComponent } from './component/estimated-actual/estimated-actual.component';
import { AccountingReportPayrollComponent } from './component/accounting-report-payroll/accounting-report-payroll.component';
import { AgentActivityComponent } from './component/agent-activity/agent-activity.component';
import { InspectorsSalesComponent } from './component/inspectors-sales/inspectors-sales.component';
import { AgentActivityReportComponent } from './component/agent-activity-report/agent-activity-report.component';
import { JobCostingBoutInspectorComponent } from './component/job-costing-bout-inspector/job-costing-bout-inspector.component';




@NgModule({
  imports: [
    CommonModule,
    ReportsRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    ChartsModule,
    BrowserModule,

    DataTablesModule,
    NgxDatatableModule
  ],
  declarations: [
  	JobcostingComponent, 
  	InspectionsScheduledComponent, 
  	PayrollComponent, 
  	EstimatedActualComponent, AccountingReportPayrollComponent, AgentActivityComponent, InspectorsSalesComponent, AgentActivityReportComponent, JobCostingBoutInspectorComponent,
  	
  ],
  exports:[
  	JobcostingComponent, 
  	InspectionsScheduledComponent,
    PayrollComponent, 
    AccountingReportPayrollComponent,
  	EstimatedActualComponent,
  	
  ]
})
export class ReportsModule { }
